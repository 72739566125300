import React, { useState } from 'react';
import './../App.css';
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaGooglePlay } from "react-icons/fa";
import { IoIosAppstore } from "react-icons/io";
import { FaRegCheckCircle } from "react-icons/fa";
import img1 from "../../src/img/1.png"
import img2 from "../../src/img/2.png"
import img3 from "../../src/img/3.png"
import img1en from "../../src/img/1 en.png"
import img2en from "../../src/img/2 en.png"
import img3en from "../../src/img/3 en.png"
import logo from "../../src/img/helpy logo.png"
import headLeft from "../../src/img/headLeft.png"
import headRight from "../../src/img/headRight.png"

import { isMobile } from "react-device-detect";
import ReactTooltip from 'react-tooltip'
import Zoom from "react-reveal/Zoom"

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import "video-react/dist/video-react.css";
import { Player } from 'video-react';

import Collapsible from 'react-collapsible';
import ProgressBar from "react-scroll-progress-bar";



import {

    Link
} from "react-router-dom";

import ReactGA from 'react-ga';

function Landing(props) {

    
    const [apk, setApk] = useState(false);


    return (
        <div>

            <ProgressBar bgcolor="#FF6366" height="7px"/>

            
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                
                <button onClick={() => props.setlanguage("de")} style={props.language == "de" ? props.style.t : props.style.f} className="language">German</button>
                <button onClick={() => props.setlanguage("en")} style={props.language == "en" ? props.style.t : props.style.f} className="language">English</button>
                </div>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                {isMobile ? <div></div> : <img className="Left" style={{ width: "250px" }} src={headLeft}></img>}
                {isMobile ? <Zoom><img style={{ width: "350px" }} src={logo}></img></Zoom> : <Zoom><img style={{ width: "600px" }} src={logo}></img></Zoom>}
                {isMobile ? <div></div> : <img className="Right" style={{ width: "250px" }} src={headRight}></img>}
            </div>
        <Zoom>
                <h2 style={{ paddingBottom: "10px", paddingTop: "100px" }}>{props.language == "de" ? <div>
                Jetzt herunterladen!</div> : <div>Download now!</div>}</h2>
            </Zoom>

            <Zoom><div style={{ display: 'flex', justifyContent: 'center' }}>
                <button data-tip="Coming soon" className="download">IOS</button>
                <button data-tip="Coming soon" className="download">ANDROID</button>
                
            </div></Zoom>
            <Zoom><div style={{ display: 'flex', justifyContent: 'center' }}>
                <button onClick={() => setApk(!apk)} className="download2" style={{ width: '250px', marginTop: "-30px" }}>
                    {props.language == "de" ? <a href='/Helpy-bc64d98f43aa4325b672f675036aabdc-signed.apk' onClick={() => {
                    ReactGA.event({
                            category: 'User',
                            action: 'Download Apk'
                        })
                    }} download>Download Apk für Android</a> 
                    :
                    <a href='/Helpy-78fcdd7e9ecb49b7b388a99a0f16aaa7-signed.apk' onClick={() => {
                        ReactGA.event({
                            category: 'User',
                            action: 'Download Apk'
                        })
                    }} download>Download APK for Android</a>}</button>
                
            </div></Zoom >
            {apk ? <div style={{ maxWidth: "400px", margin: '0 auto', marginBottom: "50px" }}>
                
                {props.language == "de" ? <h2 style={{ paddingBottom: "20px" }}>Wie installiert man eine APK?</h2> : <h2 style={{ paddingBottom: "20px" }}>How to install an APK?</h2>}
                <Player
                    playsInline
                    poster="/assets/poster.png"
                    src="/video.mp4"
                />
            </div> 
            : 
            <div></div>}
            

            <ReactTooltip place="top" type="error" effect="solid" textColor='#2F2E41' backgroundColor='#FF6366' />

            <Zoom><h2 style={{ paddingBottom: "10px", paddingTop: "100px" }}>{props.language == "de" ? <div>
                Wie funktioniert helpy?</div> : <div>How does helpy work?</div>}</h2>

            {isMobile ?
                <div style={{ width: "70%", margin: "auto" }}>
                        <div className="card">{props.language == "de" ? <img src={img1} /> : <img src={img1en} /> }</div>
                        {props.language == "de" ? <span class="caption">Helpy findet und speichert Personen, mit denen du Kontakt hast.</span> : <span class="caption">Helpy finds and stores people with whom you have contact.</span>}
                        <div className="card">{props.language == "de" ? <img src={img2} /> : <img src={img2en} />}</div>
                        {props.language == "de" ? <span class="caption">Wenn du mit SARS-CoV-2 infiziert bist, halte den Knopf gedrückt.
                    </span> : <span class="caption">If you are infected by SARS-CoV-2 hold the button.</span>}
                        <div className="cardNotification">{props.language == "de" ? <img src={img3} /> : <img src={img3en} />}</div>
                        {props.language == "de" ? <span class="caption">Du bekommst eine Nachricht, wenn einer deiner Kontaktpersonen infiziert ist.</span> : <span class="caption">Get notified if one of your contact persons is infected.</span>}
                </div>
                :

                <div style={{ display: "flex", justifyContent: 'center', flexWrap: 'wrap', marginTop: "30px" }}>
                    <div class="item">
                            <Zoom><div className="card">{props.language == "de" ? <img src={img1} /> : <img src={img1en} />}</div></Zoom>
                            {props.language == "de" ? <span className="caption">Helpy findet und speichert Personen, mit denen du Kontakt hast.</span> : <span className="caption">Helpy finds and stores people with whom you have contact.</span>}
                    </div>
                    <div class="item">
                            <Zoom><div className="card">{props.language == "de" ? <img src={img2} /> : <img src={img2en} />}</div></Zoom>
                            {props.language == "de" ? <span className="caption">Wenn du mit SARS-CoV-2 infiziert bist, halte den Knopf gedrückt.</span> : <span className="caption">If you are infected with SARS-CoV-2, press and hold the button.</span>}
                    </div>
                    <div class="item">
                            <Zoom><div className="cardNotification">{props.language == "de" ? <img src={img3} /> : <img src={img3en} />}</div></Zoom>
                            {props.language == "de" ? <span className="caption">Du bekommst eine Nachricht, wenn einer deiner Kontaktpersonen infiziert ist.</span> : <span className="caption">Get notified when one of your contacts is infected.</span>}
                    </div>
                </div>
                }</Zoom>

            <Zoom> <h2 style={{ paddingTop: "100px", marginBottom: "50px" }}>FAQ</h2>

            <div className ="CollapsibleWrapper">
                    <Collapsible trigger={props.language == "de" ? <div>Was sind "Kontaktpersonen"?</div> : <div>Who are those "contact persons"?</div>}>
                        {props.language == "de" ? <div>
                    <p>· Kontaktpersonen sind nicht etwa, alle Kontakte, die man auf seinem Handy hat! Sondern Kontaktpersonen i.S.v. Helpy und Corona meint vielmehr die Personen, mit denen ich tatsächlich in Kontakt war. Das können zum Beispiel meine Familienmitglieder sein, die gemeinsam mit mir in einem Haus wohnen. Es können aber genauso gut wildfremde Menschen sein, bspw. Leute, die beim Einkaufen in meiner Nähe waren, oder Leute, die beim Spazieren gehen an mir vorbeigelaufen sind, …</p>
                    <p>· Nur wenn die Leute in meiner Umgebung auch Helpy auf ihrem Handy haben, werden sie bei mir als Kontaktperson angezeigt.</p>
                        <p>· Dabei weißt du nicht, wer sich alles in deiner Nähe aufgehalten hat, sondern nur wie viele Menschen das waren.</p></div> : 
                        <div><p>· Contact persons are not your contacts on your phone. You don`t need their phone number. It rather describes those people you`ve had contact with anyhow. For instance your family, you live together with. But also complete strangers like people in a grocery store, who walked on by. Or even people you passed by going for a walk outside.</p>
                            <p>· Only if those people in your nearest surroundings have helpy on their phone too, they count to the number of your contact persons.
->In addition you don`t know who was around. All you know is the number of people nearby.</p>
                            </div>}
                </Collapsible>
            </div>
            <div className="CollapsibleWrapper">
                    <Collapsible trigger={props.language == "de" ? <div>Was passiert mit meinen Daten?</div> : <div>What happens with my data?</div>}>
                        {props.language == "de" ?<div><p>· Dadurch, dass du dich bei Helpy registrieren bzw. anmelden musst, verfügt Helpy über deine Anmeldedaten. Diese bleiben bei uns in Deutschland. Außerdem bleiben sie anonym.</p>
                    <p>· Zudem verfügt Helpy über deine Standortdaten, um die Personen in deiner Nähe ermitteln/zählen zu können. Dabei wird immer nur der aktuellste Standort gespeichert. Ändert sich dein Standort, so wird der neueste auf den älteren überschrieben. Auf diese Weise verfügt Helpy lediglich über den letzten aktuellen Standort, bei dem du die App geöffnet hattest.</p></div> 
                        : <div><p>· You need to sign on at Helpy. Thus helpy possesses your login credentials. These stay anonymously and stick to helpy in Germany.</p>
                            <p>· Further helpy saves your location in order to track and count the people nearby. In doing so helpy only memorizes the latest location. If you open the app in another place, helpy overwrites the old one and only saves the current location.</p></div> }
                    </Collapsible>
            </div>
            <div className="CollapsibleWrapper">
                    <Collapsible trigger={props.language == "de" ? <div>Wann bekomme ich eine Meldung/Benachrichtigung?</div> : <div>When do I get a notification?</div>}>
                        {props.language == "de" ? <div>
                        <p>Du wirst benachrichtigt, wenn</p>
                        <p>a) auch andere Leute Helpy auf ihrem Handy und geöffnet haben</p>
                        <p>b) der rote Knopf von einer infizierten Person gedrückt wurde</p>
                        <p>c) und wenn sich diese infizierte Person, in deiner näheren Umgebung aufgehalten hat und dabei auch helpy auf ihrem Handy geöffnet hatte!</p></div> : <div>
                            <p>Helpy sends you a notification, if</p>
                            <p>a) there are other people too, having helpy on their phone and unclosed</p>
                            <p>b) someone, who is infected, has pressed the red button</p>
                            <p>c) and if this contaminated person has been anyhow nearby, for instance in a grocery store. Besides this person also 
            should have unclosed helpy!</p></div>}
                </Collapsible>
            </div>
            <div className="CollapsibleWrapper">
                    <Collapsible trigger={props.language == "de" ? <div>Was muss ich machen?</div> : <div>What am I supposed to do?</div>}>
                        {props.language == "de" ? <div><p>· Wenn du einkaufen oder spazieren gehen willst, oder wenn du sonst aus dem Haus gehst, dann nimm dein Handy mit und öffne die App Helpy.</p>
                        <p>· Auf diese Weise ermittelt Helpy alle Personen, die sich in deiner Nähe aufgehalten haben - sofern diese auch Helpy auf ihrem Handy und geöffnet haben -!</p></div> : 
                        <div><p>· If you want to leave your house in order to go for a walk or if you need to go to the grocery store, you should take your phone with helpy on it with you and open the app.</p>
                            <p>· In this manner, helpy tracks everyone around you - as long as they also have helpy unclosed on their phone -!</p></div>}
                </Collapsible>
            </div>
            <div className="CollapsibleWrapper">
                    <Collapsible trigger={props.language == "de" ? <div>Warum soll ich mir Helpy herunterladen?</div> : <div>Why should I download helpy on my phone?</div>}>
                        {props.language == "de" ? <p>· Nur wenn möglichst alle Leute Helpy haben, funktioniert die Idee dahinter. Denn Leute, die wir bereits kennen und deren Nummer wir auf unserem Handy haben, können wir auch einfach so benachrichtigen, falls wir infiziert sind. Oder sie uns. Hingegen ist das bei Leuten, die wir noch nie gesehen haben oder die uns einfach so beim Einkaufen über den Weg laufen nicht möglich. Aus diesem Grund brauchst du Helpy auf deinem Handy, genauso wie jeder andere. Denn nur, wenn wir alle zusammenhalten, können wir Corona bekämpfen!</p> 
                        : <p> The idea of helpy only works if as many people as possible have helpy on their phone. Look, we can talk to people we already know and whose number we`ve already got on our phone. We can already inform them. However we can`t inform those people we`ve never even seen bevor. Those who just walked on by in a grocery store. We don`t even know them so there`s no way to tell them if we`re tested positive. This is exactly why everybody needs helpy. Only if we stick together and all download helpy on our phones, we can fight against corona! </p>}
                </Collapsible>
            </div>
            <div className="CollapsibleWrapper">
                    <Collapsible trigger={props.language == "de" ? <div>Was macht Helpy so besonders?</div> : <div>What is special about helpy?</div>}>
                        {props.language == "de" ? <div><p>· Helpy trackt nicht nur die Menschen, die sich in deiner Nähe aufgehalten haben.</p>
                    <p>· Mit Helpy erhältst du zudem einen Überblick über die aktuelle Situation von Corona weltweit. Dabei veranschaulicht dir eine Statistik, wie viele Menschen in welchen Ländern infiziert und wie viele wieder genesen sind.</p>
                        <p>· Darüber hinaus bietet Helpy die Möglichkeit eines Einkaufs-Services für den Fall, dass du zu Hause in Quarantäne bleiben musst, nicht einkaufen gehen kannst und niemanden hast, der das für die erledigen kann. </p>
                        <p>· Außerdem können wir alle gemeinsam mit Helpy der Pandemie ein Ende bereiten, um möglichst schnell wieder zu unserem gewohnten und normalen Alltag zurückzukehren!</p></div> : 
                    <div><p>· Helpy doesn`t only track people nearby.</p>
                        <p>· It also consists of a statistic, demonstrating the demension of corona worldwide. Hence it illustrates, how many people in which country are infected.</p>
                        <p>· Furthermore it offers the opportunity, to get yourself someone who goes shopping for you, if you have to stay at home in quarantine. </p>
                        <p>· Together we can fight against this pandemic worlwide with helpy in order to go back to normal as soon as possible!</p></div> }
                </Collapsible>
                </div></Zoom>

            <h2 style={{ paddingTop: "150px", marginBottom: "50px" }}>Timeline</h2>

            <div style={{ margin: "50px 0px 50px 0px" }}>
                <VerticalTimeline style={{ color: "black" }}>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#FF6366', color: 'white' }}
                        contentArrowStyle={{ borderRight: '7px solid  #FF6366' }}
                        date=""
                        iconStyle={{ background: '#FF6366', color: '#fff' }}
                        icon={<FaRegCheckCircle />}
                    >
                        {props.language == "de" ? <h3 className="vertical-timeline-element-title">Erste Version von Helpy</h3> : <h3 className="vertical-timeline-element-title">First Version of Helpy</h3>}
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#2F2E41', color: 'white' }}
                        contentArrowStyle={{ borderRight: '7px solid  #2F2E41' }}
                        date=""
                        iconStyle={{ background: '#2F2E41', color: '#fff' }}
                    >
                        {props.language == "de" ? <h3 className="vertical-timeline-element-title">
                            Verbesserung der Kontaktsuche</h3> : <h3 className="vertical-timeline-element-title">Contacts searching improvement</h3>}
                        {props.language == "de" ? <h4 className="vertical-timeline-element-subtitle">Kommt bald</h4> : <h4 className="vertical-timeline-element-subtitle">Coming soon</h4>}
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#2F2E41', color: 'white' }}
                        contentArrowStyle={{ borderRight: '7px solid  #2F2E41' }}
                        date=""
                        iconStyle={{ background: '#2F2E41', color: '#fff' }}
                    >
                        {props.language == "de" ? <h3 className="vertical-timeline-element-title">
                            Bist du in Quarantäne?</h3> : <h3 className="vertical-timeline-element-title">Are you in quarantine?</h3>}
                        {props.language == "de" ? <h4 className="vertical-timeline-element-subtitle">
                            Lass andere für dich Lebensmittel kaufen.</h4> : <h4 className="vertical-timeline-element-subtitle">Let others buy groceries for you.</h4>}
                        {props.language == "de" ? <h4 className="vertical-timeline-element-subtitle">Kommt bald</h4> : <h4 className="vertical-timeline-element-subtitle">Coming soon</h4>}
                    </VerticalTimelineElement>
                </VerticalTimeline></div>



            <div style={{ backgroundColor: "#2F2E41", padding: "50px", borderRadius: "20px 20px 0px 0px", boxShadow: "0px 3px 6px 0px rgba(0,0,0,0.65)" }}>


                {props.language == "de" ? <Link to="/agb"><div className="agb">Allgemeine Geschäftsbedingungen · Datenschutzerklärung</div></Link> : <Link to="/agb"><div className="agb">
                    General terms and conditions · Data protection declaration</div></Link> }

                <div style={{ display: "flex", justifyContent: 'center' }}><a style={{ color: "white", padding: "40px 10px 10px 10px", fontSize: "17px" }}>Contact us: <a style={{ fontWeight: "bold", color: "white" }}>ceastartup@gmail.com</a></a></div>
            </div>
        </div>
    );
}

export default Landing;