import React, { useState } from 'react';

import './App.css';
import Landing from '../src/components/landing.js'
import AGB from '../src/components/agb.js'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import ReactGA from 'react-ga';

function initializeReactGA() {
  ReactGA.initialize('UA-161824546-1');
  ReactGA.pageview('/landing');
}


const style =
{
  t:
  {
    padding: '10px',
    fontWeight: 'bold',
    fontSize: '18px',
    backgroundColor: '#FF6366',
    borderRadius: '20px',
    width: '120px',
    margin: '10px',
    marginLeft: '5px',
    marginRight: '5px',
    textAlign: 'center',
    color: '#2F2E41',
    border: 'none',
    textDecoration: 'none',
    outline: 'none'
  },
  f:
  {
    padding: '10px',
    fontWeight: 'bold',
    fontSize: '18px',
    backgroundColor: '#2F2E41',
    borderRadius: '20px',
    width: '120px',
    margin: '10px',
    marginLeft: '5px',
    marginRight: '5px',
    textAlign: 'center',
    color: '#FF6366',
    border: 'none',
    textDecoration: 'none',
    outline: 'none',
    opacity: '0.3'
  }
}



function App() {
  const [language, setlanguage] = useState("de");
  initializeReactGA()
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <Landing style={style} language={language} setlanguage={setlanguage}></Landing>
          </Route>
          <Route path="/agb">
            <AGB style={style} language={language} setlanguage={setlanguage}/>
          </Route>
        </Switch>
      </div>
    </Router>

  );
}

export default App;