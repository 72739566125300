import React from "react"
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io"
const back = {
    padding: '10px',
    fontWeight: 'bold',
    fontSize: '18px',
    backgroundColor: '#FF6366',
    borderRadius: '100px',
    width: '40px',
    margin: '10px',
    marginLeft: '35px',
    marginRight: '5px',
    textAlign: 'center',
    color: '#2F2E41',
    border: 'none',
    textDecoration: 'none',
    outline: 'none'}

function AGB (props)
{
    return(
        <div>
            <div style={{ display: 'grid', gridTemplateColumns: "repeat(3, 1fr)" }}>
                <Link to="/"><div style={back}>
                    <IoMdArrowRoundBack/>
                </div></Link>
                <div style ={{display: "flex", justifyContent: "center"}}>

                    <button onClick={() => props.setlanguage("de")} style={props.language == "de" ? props.style.t : props.style.f} className="language">German</button>
                    <button onClick={() => props.setlanguage("en")} style={props.language == "en" ? props.style.t : props.style.f} className="language">English</button>
                </div>
            </div>

            <div style ={{padding: "20px"}}>
            {props.language == "de" ? <div><h2 style={{ textAlign: "left" }}>Allgemeine Geschäftsbedingungen (AGB):</h2>

                <div style={{ margin: "20px" }}>
                    <h3>§ 1 Geltungsbereich</h3>
                    <p>a) Die nachfolgenden Bedingungen gelten für die Nutzer der App "Helpy".</p>
                    <p>b) Mit der Verwendung der App erklärt sich der jeweilige Nutzer mit diesen Bedingungen einverstanden.</p>
                    <p>c) Diese Bedingungen gelten gegenüber Verbrauchern.</p>

                    <h3>§ 2 Datenerhebung</h3>
                    <p>a) Lediglich während der Nutzunng der App werden die aktuellen Standort-Daten der einzelnen Nutzer gespeichert. Bei Verlassen der App endet der Zugriff auf den Standort.</p>
                    <p>b) Dabei wird nur der aktuellste Standort gespeichert. Verlässt der Nutzer die App, endet die Ortung des Handys und damit die aktuelle Standort-Speicherung. Bei erneutem Aufrufen der App werden neue Standort-Daten erhoben und anschließend über die älteren überschrieben. Dadurch wird ausschließlich auf den augenblicklichen Standort zugegriffen. </p>
                    <p>c) Auf ältere bereits überschriebene Daten besteht kein Zugriff.</p >
                    <p>d) Darüber hinaus werden personenbezogene Daten, wie die E-Mail und das Passwort des Nutzers, bei der Registrierung aufgenommen und dauerhaft gespeichert. Diese Daten werden anschließend anonymisiert, sodass ein Rückgriff auf die einzelne Person des Nutzers nicht möglich ist.</p>

                    <h3>§ 3 Datenspeicherung und /-Nutzung</h3>
                    <p>a) Die erhobenen Daten werden ausschließlich zur Ermittlung der Kontaktpersonen der Nutzer verwendet.</p>
                    <p>b) Auf diese Weise erhalten die Nutzer eine Benachrichtigung über mögliche infizierte Personen, die mit Ihnen in Kontakt waren.</p>
                    <p>c) Die personenbezogenen Daten (E-Mail und Passwort des Nutzers) werden hierbei  zur Authentifizierung der Nutzer gespeichert.</p>
                </div>
                </div> 
                : 
                <div><h2 style={{ textAlign: "left" }}>General terms and conditions:</h2>

                    <div style={{ margin: "20px" }}>
                        <h3>1. Area of application  </h3>
                        <p>a) The following terms and conditions apply to the user of Helpy.</p>
                        <p>b) By using this app, each user complies with these conditions.</p>
                        <p>c) In addition these conditions apply towards consumers.</p>

                        <h3>2. Data gathering</h3>
                        <p>a) The latest data of location of each user is only saved during the current utilization of the app.</p>
                        <p>b) Thereby Helpy only saves the users latest location. If the user leaves the app, the position finding stops. This way Helpy also stops saving the users current location. If the user now opens Helpy again, the app starts collecting the data of location of this user anew. In doing so it overwrites the old location with the new one. Thus Helpy only has access to the users current position.</p>
                        <p>c) In relation to the data which has already been overwritten, Helpy has no access.</p >
                        <p>d) Furthermore Helpy records the users personal data, like its e-mail address and its keyword at the registration. Helpy saves this personal data permanently. Moreover it immediately makes the users personal data anonymous. This way there’s no chance coming back to each individual person of every user.</p>

                        <h3>3. Data handling and storage of data</h3>
                        <p>a) The upraised data solely is used in order to determine the users contact persons.</p>
                        <p>b) Hence the user is able to get a notification about people, he/she has been in contact with, that might be infected.</p>
                        <p>c) At this juncture the personal data (e-mail address and keyword) only is saved for the authentication of the user.</p>
                    </div>
                </div>}                                                                                              
            </div></div>
    )
}

export default AGB